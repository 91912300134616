import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'store/reducers/userReducer';
import { useNavigate } from 'react-router-dom';
import { UnstyledButton, Group, Avatar, Text, rem, Menu } from '@mantine/core';
import { IconChevronRight, IconLogout, IconMoodHappy, IconSettings, IconUser } from '@tabler/icons-react';
import classes from './UserButton.module.css';
import { leaveRoomAndLogout } from 'store/actions/userActions';
import { getWSConnection } from 'store/reducers/serverReducer';
import { BadgeList } from 'components/Badge';

export function UserButton() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const wsConnection = useSelector(getWSConnection);

  const goToLogin = () => [navigate('/')];

  const logoutUser = () => {
    dispatch(leaveRoomAndLogout());
    navigate('/');
  };

  if (!user.loggedIn) {
    return (
      <UnstyledButton className={classes.user} onClick={goToLogin}>
        <Group>
          <Avatar radius="xl" color="red">
            <IconUser />
          </Avatar>

          <div style={{ flex: 1 }}>
            <Text size="sm" fw={500}>
              Déconnecté
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    );
  } else {
    return (
      <Menu shadow="md" width={200} position="right">
        <Menu.Target>
          <UnstyledButton className={classes.user}>
            <Group>
              <div style={{ position: 'relative' }}>
                <Avatar radius="xl" color="indigo" src={user.avatar}>
                  {!user.avatar && <IconUser />}
                </Avatar>

                <div
                  style={{
                    position: 'absolute',
                    top: '0px',
                    right: '0px',
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: wsConnection ? 'green' : 'red',
                    zIndex: 1,
                  }}
                />
              </div>

              <div style={{ flex: 1 }}>
                <Text size="sm" fw={500}>
                  {user.username}
                </Text>

                {/* <Text c="dimmed" size="xs"> */}
                <Group style={{ gap: 5 }}>
                  <BadgeList user={user} />
                </Group>

                {/* </Text> */}
              </div>

              <IconChevronRight style={{ width: rem(14), height: rem(14) }} stroke={1.5} />
            </Group>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>{user.username}</Menu.Label>
          <Menu.Item disabled leftSection={<IconMoodHappy style={{ width: rem(14), height: rem(14) }} />}>
            Profil
          </Menu.Item>
          <Menu.Item disabled leftSection={<IconSettings style={{ width: rem(14), height: rem(14) }} />}>
            Paramètres
          </Menu.Item>

          <Menu.Divider />

          <Menu.Label>Actions</Menu.Label>
          <Menu.Item
            color="red"
            leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}
            onClick={logoutUser}
          >
            Se déconnecter
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  }
}
