import {
  ActionIcon,
  Affix,
  Avatar,
  Badge,
  Button,
  CheckIcon,
  Container,
  Flex,
  Group,
  Paper,
  Text,
  Title,
  Tooltip,
  rem,
} from '@mantine/core';
import {
  IconCircleCheckFilled,
  IconCrown,
  IconDoorExit,
  IconLock,
  IconLockOpen,
  IconStarFilled,
  IconThumbDown,
  IconThumbUp,
  IconUser,
} from '@tabler/icons-react';
import { ButtonCopy } from 'components/ButtonCopy';
import { LeaveButton } from 'components/LeaveButton';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typical from 'react-typical';
import { wsEvent } from 'store/actions/wsActions';
import { selectUser } from 'store/reducers/userReducer';

class PlayersTitle extends React.PureComponent {
  render() {
    const playersTitles = [
      'Les scribouilleurs ✏️',
      'Les gratteurs 📝',
      'Les trouvailleurs 🔍',
      'Les écrivailleurs 📚',
      'Les cervelo 🧠',
      'Les flashmeurs ⚡',
      'Les funscribes 🎉',
      'Les brainstormeurs 💭',
      'Les inspirés 💡',
      'Les intellectuels 🤓',
    ];

    return (
      <Typical
        steps={playersTitles.flatMap((participant) => [participant, 3000])}
        loop={Infinity}
        // wrapper="span"
      />
    );
  }
}

export default function PrivateRoom() {
  const room = useSelector((state) => state.room);

  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const currentUserInRoom = room.users.find((u) => u.uuid === user.uuid);

  const isAdmin = (potentialUser = null) => {
    if (!potentialUser) {
      return currentUserInRoom.admin ?? false;
    }
    return room.users.find((u) => u.uuid === potentialUser.uuid)?.admin ?? false;
  };

  const isFounder = () => {
    let roomUser = room.users.find((u) => u.uuid === user.uuid);
    return roomUser?.founder ?? false;
  };

  const leaveRoomAction = (uuid = user.uuid) => {
    dispatch(
      wsEvent({
        event: 'user_leave_room',
        payload: {
          roomId: room.roomId,
          userId: uuid,
        },
      })
    );
  };

  const changeRoomVisibility = () => {
    if (!isAdmin()) {
      return;
    }
    dispatch(
      wsEvent({
        event: 'update_room',
        payload: {
          roomId: room.roomId,
          data: {
            visibility: room.visibility === 'private' ? 'public' : 'private',
          },
        },
      })
    );
  };

  const changeUserReadyState = () => {
    dispatch(
      wsEvent({
        event: 'update_user_room',
        payload: {
          roomId: room.roomId,
          userId: user.uuid,
          data: {
            ready: !currentUserInRoom.ready,
          },
        },
      })
    );
  };

  const badges = (user) => {
    switch (user.type) {
      case 'guest':
        return (
          <>
            <Badge size="xs" color="orange">
              Invité
            </Badge>
          </>
        );

      case 'client':
        return (
          <>
            <Tooltip label="Vérifié" position="top">
              <Badge size="xs" color="green">
                <CheckIcon
                  style={{ width: rem(8), height: rem(8) }}
                  // stroke={1.5}
                />
              </Badge>
            </Tooltip>
            {/* Map users badges */}
            {(user?.badges ?? []).map((badge, index) => {
              switch (badge) {
                case 'friend':
                  return (
                    <Badge key={index} size="xs" color="orange">
                      <IconStarFilled color="gold" style={{ width: rem(8), height: rem(8) }} />
                      Ami
                    </Badge>
                  );
                default:
                  return null;
              }
            })}
          </>
        );
      default:
        return null;
    }
  };

  const canUserStartGame = () => {
    let allUsersReady = room.users.every((u) => u.ready);
    return allUsersReady;
  };

  return (
    <Container
      m={0}
      p={0}
      fluid
      flex="1"
      style={{
        position: 'relative',
        height: 'calc(100vh - 2rem)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Paper shadow="lg" radius="xl" p="md" bg="var(--mantine-color-dark-6)">
        <Flex direction="row" align="center" justify="space-between">
          <Flex direction="row" align="center">
            <Button
              variant="light"
              rightSection={
                room.visibility === 'private' ? (
                  <IconLock style={{ width: rem(20), height: rem(20) }} />
                ) : (
                  <IconLockOpen style={{ width: rem(20), height: rem(20) }} />
                )
              }
              radius="xl"
              size="md"
              styles={{
                root: { paddingRight: rem(14), height: rem(48) },
                section: { marginLeft: rem(22) },
              }}
              color={room.visibility === 'private' ? 'red' : 'green'}
              onClick={() => changeRoomVisibility()}
            >
              {room.visibility === 'private' ? 'Partie privée' : 'Partie ouverte'}
            </Button>
            <Text size="lg" style={{ marginLeft: rem(20), fontWeight: 500 }}>
              {room.name}
            </Text>
          </Flex>

          <Group>
            <ButtonCopy url={`${window.location.origin}/join/${room.roomId}`} />

            <Button
              variant="light"
              rightSection={
                currentUserInRoom.ready ? (
                  <IconThumbUp style={{ width: rem(20), height: rem(20) }} />
                ) : (
                  <IconThumbDown style={{ width: rem(20), height: rem(20) }} />
                )
              }
              radius="xl"
              size="md"
              styles={{
                root: { paddingRight: rem(14), height: rem(48) },
                section: { marginLeft: rem(22) },
              }}
              color={currentUserInRoom.ready ? 'green' : 'red'}
              onClick={() => changeUserReadyState()}
            >
              {currentUserInRoom.ready ? 'Prêt' : 'Pas prêt'}
            </Button>

            <LeaveButton onClick={() => leaveRoomAction()} />
          </Group>
        </Flex>
      </Paper>
      {/* Configuration */}
      <Flex style={{ flex: 1, height: '100%', overflow: 'auto' }} direction="row" mt={30}>
        {/* Settings */}
        <Container flex={2} p={0} m={0}>
          <Title order={3}>⚙️ Paramètres de la partie</Title>
        </Container>
        {/* Players */}
        <Container
          flex={1}
          p={0}
          m={0}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Title order={3}>
            <PlayersTitle />
          </Title>
          {/* <Flex direction="column" style={{ flex: 1, height: '100%' }}> */}

          <Container
            p={0}
            m={0}
            // listStyleType="none"
            style={{
              overflowY: 'auto',
              height: '100%',
              //  maxHeight: '60vh',
            }}
          >
            {room.users.map((roomUser) => (
              /* {Array(50)
              .fill(room.users[0])
              .map((roomUser) => ( */

              <Paper
                key={roomUser.uuid}
                shadow="lg"
                radius="lg"
                p="md"
                bg="var(--mantine-color-dark-6)"
                style={{
                  width: '100%',
                }}
                flex="1"
                mt={10}
              >
                <Group
                  style={{
                    width: '100%',
                  }}
                  flex="1"
                >
                  <Tooltip
                    label={roomUser.founder ? 'Créateur de la partie' : ''}
                    position="left"
                    withArrow
                    transition="fade"
                    bg="var(--mantine-color-dark-1)"
                    display={roomUser.founder ? 'flex' : 'none'}
                  >
                    <div
                      style={{
                        position: 'relative',
                      }}
                    >
                      {roomUser.founder && (
                        <IconCrown
                          style={{
                            width: rem(20),
                            height: rem(20),
                            position: 'absolute',
                            top: '-30%',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 10,
                          }}
                          stroke={2}
                          color="#ffbf00"
                          fill="#ffbf00"
                        />
                      )}

                      <Avatar
                        radius="xl"
                        color="indigo"
                        src={roomUser.avatar}
                        // add gold border
                        style={
                          roomUser.founder
                            ? {
                                border: '2px solid #ffbf00',
                                zIndex: 100,
                              }
                            : {}
                        }
                        bg={`var(--mantine-color-dark-8`}
                      >
                        {!roomUser.avatar && <IconUser />}
                      </Avatar>
                    </div>
                  </Tooltip>
                  <div style={{ flex: 1 }}>
                    <Text size="sm" fw={500}>
                      {roomUser.username}
                    </Text>

                    {/* <Text c="dimmed" size="xs"> */}
                    <Group style={{ gap: 5 }}>{badges(roomUser)}</Group>

                    {/* </Text> */}
                  </div>
                  <Flex direction="row" align="center">
                    <IconCircleCheckFilled
                      style={{
                        width: rem(30),
                        height: rem(30),
                        color: roomUser.ready ? 'var(--mantine-color-green-6)' : 'var(--mantine-color-dark-1)',
                      }}
                      stroke={1.5}
                    />
                    {isFounder(roomUser) && (
                      <Tooltip
                        label="Exclure de la partie"
                        position="left"
                        withArrow
                        transition="fade"
                        bg="var(--mantine-color-dark-1)"
                      >
                        <Flex direction="row" align="center" ml={10}>
                          <ActionIcon
                            variant="transparent"
                            aria-label="Settings"
                            disabled={room.users.find((u) => u.uuid === user.uuid).uuid === roomUser.uuid}
                            onClick={() => {
                              leaveRoomAction(roomUser.uuid);
                            }}
                          >
                            <IconDoorExit
                              style={{
                                width: rem(20),
                                height: rem(20),
                              }}
                            />
                          </ActionIcon>
                        </Flex>
                      </Tooltip>
                    )}
                  </Flex>
                </Group>
              </Paper>
            ))}
          </Container>
          {/* </Flex> */}

          <Container
            mt={20}
            p="sm"
            bg="var(--mantine-color-dark-6)"
            shadow="xl"
            radius="xl"
            style={{ width: '100%', borderRadius: rem(20) }}
          >
            <Flex direction="column" align="center" justify="center">
              <Text size="xs" align="center" style={{ fontWeight: 500 }}>
                Tous les joueurs doivent être prêts pour lancer la partie
              </Text>
              {isFounder() && (
                <Button
                  variant="light"
                  // rightSection={<IconPlayerPlayFilled style={{ width: rem(20), height: rem(20) }} />}
                  radius="xl"
                  size="sm"
                  mt={10}
                  styles={{
                    root: {
                      // paddingRight: rem(14),
                      width: '100%',
                      height: rem(48),
                    },
                    // section: { marginLeft: rem(22) }
                  }}
                  color="blue"
                  disabled={!canUserStartGame()}
                  onClick={() => {}}
                >
                  Commencer la partie
                </Button>
              )}
              {!isFounder() && (
                <Text size="sm" align="center" mt={20} style={{ fontWeight: 500 }}>
                  En attente du chef de la partie ...
                </Text>
              )}
            </Flex>
          </Container>
        </Container>
      </Flex>
    </Container>
  );
}
