import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const slice = createSlice({
  name: "room",
  initialState,

  reducers: {
    joinRoom(state, action) {
      let payload = action.payload;
      payload.joinedDate = new Date().getTime();
      return payload;
    },
    updateRoom(state, action) {
      let payload = action.payload;
      payload.joinedDate = state.joinedDate || new Date().getTime();
      return payload;
    },
    leaveRoom(state, action) {
      return initialState;
    },
  },
});

export const { joinRoom, updateRoom, leaveRoom } = slice.actions;

export default slice.reducer;
