import { notifications } from '@mantine/notifications';
import { leaveRoomAndLogout } from 'store/actions/userActions';
import { leaveRoom } from 'store/reducers/roomReducer';
import { updateRoom } from 'store/reducers/roomReducer';
import { joinRoom } from 'store/reducers/roomReducer';
import { setClientsCount } from 'store/reducers/serverReducer';
import { login } from 'store/reducers/userReducer';

function receiveWebsocketMessage(getState, dispatch, socket, event, data) {
  console.log('receiveWebsocketMessage', event, data);

  const userId = getState().user.uuid;

  switch (event) {
    case 'refresh':
      let TTF = 5000; //process.env.REACT_APP_TTF || 5000;
      notifications.show({
        title: 'Mise à jour',
        message: `Le serveur a été mis à jour, la page va se rafraîchir dans ${TTF / 1000} secondes`,
        color: 'red',
        autoClose: TTF,
      });

      setTimeout(() => {
        dispatch(leaveRoomAndLogout(true));
      }, TTF);
      break;
    /* Login */
    case 'login':
      dispatch(login(data));

      notifications.show({
        loading: false,
        title: 'Connecté',
        message: 'Vous êtes connecté !',
        autoClose: true,
        withCloseButton: true,
      });
      break;
    /* Join room */
    /* case "join_room":
      dispatch(joinRoom(data));

      notifications.show({
        loading: false,
        title: "Nouvelle partie",
        message: "Vous avez rejoint une nouvelle partie !",
        autoClose: true,
        withCloseButton: true,
      });
      break;*/
    /* Update room */
    case 'update_room':
      dispatch(updateRoom(data));
      break;
    /* User count */
    case 'client_count':
      dispatch(setClientsCount(data));
      break;
    /* User leave room*/
    case 'user_leave_room':
      dispatch(leaveRoom());
      break;
    /* Force user to leave a room */
    case 'force_leave_room':
      socket.emit('user_leave_room', { roomId: data.roomId, userId });
      break;
    default:
      break;
  }
}

export default receiveWebsocketMessage;
