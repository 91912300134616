import { apiRequest } from 'helpers/request';
import receiveWebsocketMessage from 'hooks/websocketMessage';
import { io } from 'socket.io-client';
import { wsEvent } from 'store/actions/wsActions';
import { wsConnect } from 'store/actions/wsActions';
import { setBadges } from 'store/reducers/datasReducer';
import { leaveRoom } from 'store/reducers/roomReducer';
import { setWSConnection } from 'store/reducers/serverReducer';

export let socket;
let readyForConnection = false;

const MAXIMUM_TIME_ROOM_INACTIVITY = 600000;

function closeWebSocket() {
  if (socket) {
    socket.close();
    socket = null;
  }
}

export const websocketMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (!action) {
      return;
    }
    /* Connect WS */
    if (wsConnect.match(action)) {
      readyForConnection = true;
      if (socket) {
        console.log('Socket already connected => close');
        closeWebSocket();
      }
    }

    if (readyForConnection && !socket) {
      /* Create socket connection */
      socket = io(process.env.REACT_APP_BACKEND_URL, { secure: true });
      /* Socket connection establish */
      socket.on('connect', async () => {
        let userUUID = getState().user.uuid;
        let room = getState().room;

        if (userUUID) {
          socket.emit('recover_login', userUUID);

          if (room && room.roomId) {
            let actualTime = new Date().getTime();
            let joinedRoomTime = room.joinedDate;
            let timeDifference = actualTime - joinedRoomTime;
            // If the user has been inactive for more than 10 minutes, leave the room
            if (timeDifference > MAXIMUM_TIME_ROOM_INACTIVITY) {
              dispatch(leaveRoom());
              return;
            }

            let previousUserRoomState = room.users.find((user) => user.uuid === userUUID);

            socket.emit('recover_user_room', {
              roomId: room.roomId,
              data: previousUserRoomState,
            });
          }
        }

        dispatch(setWSConnection(false));
        setTimeout(() => {
          console.log('Socket connected');
          dispatch(setWSConnection(true));
        }, 1000);

        // Update store
        let result = await apiRequest({
          method: 'post',
          url: '/getRessource',
          data: {
            collection: 'badge',
            sort: { rank: 1, name: 1 },
          },
        });

        dispatch(setBadges(result?.ressources ?? []));
      });
      /* Socket disconnected */
      socket.on('disconnect', () => {
        console.log('Socket disconnected');
        dispatch(setWSConnection(false));
      });
      /* When receive any event */
      socket.onAny((event, data) => {
        // Websocket message handler
        switch (event) {
          default:
            receiveWebsocketMessage(getState, dispatch, socket, event, data);
            break;
        }
      });
      /* Socket error */
      socket.on('connect_error', (err) => {
        console.log('Socket connection error', err);
        dispatch(setWSConnection(false));
      });
    }
    /* Send WS event */
    if (wsEvent.match(action)) {
      if (socket?.connected) {
        socket.emit(action.payload.event, action.payload.payload, action.payload.callback ?? (() => {}));
      }
    }

    next(action);
  };
