import { useSelector } from "react-redux";
import PrivateRoom from "./Private";

export default function WaitingRoom() {
	const room = useSelector(state => state.room);

	switch (room.gameMode) {
		case "normal":
			return <h1>normal</h1>;
		case "ranked":
			return <h1>ranked</h1>;
		default:
			return <PrivateRoom />;
	}
}
