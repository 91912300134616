/* eslint-disable jsx-a11y/alt-text */
import '@mantine/carousel/styles.css';
import { Button, Card, Container, Divider, Flex, Group, Input, Text, Title } from '@mantine/core';
import { DiscordIcon } from '@mantinex/dev-icons';
import { GoogleIcon } from 'components/GoogleIcon';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/reducers/userReducer';
import ConnectedHome from './ConnectedHome';
import { useNavigate } from 'react-router-dom';
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { apiRequest } from 'helpers/request';

import NewsKnowImage from 'assets/images/news/know.jpg';

export function GoogleButton(props) {
  return <Button leftSection={<GoogleIcon />} variant="default" {...props} />;
}

export function DiscordButton(props) {
  return (
    <Button
      className="discordButton"
      leftSection={<DiscordIcon style={{ width: '1rem', height: '1rem' }} />}
      {...props}
    />
  );
}

const Slide = ({ title, content, type }) => {
  const getImage = () => {
    switch (type) {
      case 'know':
        return NewsKnowImage;
      default:
        return '';
    }
  };

  return (
    <Carousel.Slide>
      <Flex
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Container
          style={{
            width: '10vw',
            height: '100%',
          }}
          // bg={`var(--mantine-color-red-9)`}
          p={0}
          m={0}
        >
          <img
            src={getImage()}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Container>
        <Flex direction="column" flex="1" m={20}>
          <Text size="xl" fw={500} mb={30}>
            {title}
          </Text>
          <Text dangerouslySetInnerHTML={{ __html: content }}></Text>
        </Flex>
      </Flex>
    </Carousel.Slide>
  );
};

const UnLoggedUser = () => {
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  const [news, setNews] = useState([]);

  const autoplay = useRef(Autoplay({ delay: 10000 }));

  const connectUser = async () => {
    navigate('/login/guest', { state: { username } });
  };

  const currentBaseURL = window.location.origin;
  const discordClientID = '1227006810322636930';
  const redirectDiscordURL = `${currentBaseURL}/login/`;

  const state = {};

  const url = `https://discord.com/oauth2/authorize?client_id=${discordClientID}&response_type=token&redirect_uri=${encodeURIComponent(
    redirectDiscordURL
  )}discord&scope=identify&state=${encodeURIComponent(JSON.stringify(state))}`;

  const getNews = async () => {
    let result = await apiRequest({
      method: 'post',
      url: '/getRessource',
      data: {
        collection: 'news',
      },
    });

    let ressources = result.ressources ?? [];

    // Shuffle ressources
    ressources.sort(() => Math.random() - 0.5);
    setNews(ressources);
  };

  useEffect(() => {
    getNews();
  }, []);

  return (
    <Flex flex="1" direction="column" justify="space-between" align="center" style={{ height: '100%', width: '100%' }}>
      <Container p={30} flex="1">
        <Title order={1}> Bienvenue sur le petit bac</Title>
        <Text size="xl" fw={500}>
          Se connecter avec
        </Text>

        <Group grow mb="md" mt="md">
          <GoogleButton disabled>Google</GoogleButton>
          <DiscordButton component="a" href={url}>
            Discord
          </DiscordButton>
        </Group>

        <Divider my="xs" label="ou" labelPosition="center" />

        <Group grow mb="md" mt="md">
          <Input
            placeholder="Nom d'utilisateur"
            value={username}
            onChange={(e) => setUsername(e.currentTarget.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                connectUser();
              }
            }}
          />
          <Button disabled={!username.trim()} variant="filled" onClick={connectUser}>
            Continuer en tant qu'invité
          </Button>
        </Group>

        <Container m={20} mt={60}>
          <Text size="md" fw={500}>
            Connectez vous pour accéder à toutes les fonctionnalités
          </Text>
        </Container>
      </Container>
      <Container
        m={10}
        mb={60}
        flex="1"
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <Card
          withBorder
          radius="md"
          bg="var(--mantine-color-body)"
          style={{
            height: '100%',
            width: '100%',
            padding: 0,
            margin: 0,
          }}
        >
          <Carousel
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.play}
            slideSize="100%"
            slideGap="md"
            controlSize={25}
            loop
            draggable={false}
            withControls={false}
            // withIndicators
            height="100%"
            style={{ flex: 1 }}
          >
            {news.map((news) => (
              <Slide key={news.id} title={news.title} content={news.content} type={news.type} />
            ))}
          </Carousel>
        </Card>
      </Container>
    </Flex>
  );
};

const Home = () => {
  const user = useSelector(selectUser);

  if (user.loggedIn) {
    return <ConnectedHome user={user} />;
  } else {
    return <UnLoggedUser />;
  }
};

export default Home;
