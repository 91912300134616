import './index.css';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import { createTheme, MantineProvider } from '@mantine/core';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from 'pages/Error';
import { store } from 'store/store';
import ProtectedRoute from './ProtectedRoute';
import { Page } from 'pages';
import { routes } from 'routes';
import { Notifications } from '@mantine/notifications';
import { wsConnect } from 'store/actions/wsActions';
import { leaveRoomAndLogout } from 'store/actions/userActions';

const container = document.getElementById('root');
const root = createRoot(container);

const App = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Connect to the WebSocket server
    dispatch(wsConnect());

    // On boot, if front version is different from local storage version, clear local storage and log out user
    const localVersion = localStorage.getItem('version');
    const frontVersion = process.env.REACT_APP_VERSION;

    if (localVersion !== frontVersion) {
      localStorage.setItem('version', frontVersion);
      dispatch(leaveRoomAndLogout());
    }
  }, []);

  return children;
};

function getComponent(component, privateRoute = true, options = {}) {
  if (privateRoute) {
    return (
      <ProtectedRoute>
        <Page options={options}>{component}</Page>
      </ProtectedRoute>
    );
  } else {
    return <Page options={options}>{component}</Page>;
  }
}

const router = createBrowserRouter(
  routes.map((route) => {
    return {
      path: route.path,
      element: getComponent(route.component, route.private, route.options),
      errorElement: <ErrorPage />,
    };
  })
);

const theme = createTheme({
  colors: {},
  primaryColor: 'indigo',
});

root.render(
  <Provider store={store}>
    <MantineProvider theme={theme} defaultColorScheme="dark">
      <App>
        <Notifications position="bottom-right" zIndex={1000} />
        <RouterProvider router={router} />
      </App>
    </MantineProvider>
  </Provider>
);
