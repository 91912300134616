/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Container, Group, Table, Title, rem } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "store/reducers/userReducer";
import classes from "./Menu.module.css";
import { IconCirclePlus, IconLogin } from "@tabler/icons-react";
import { wsEvent } from "store/actions/wsActions";
import { useEffect, useState } from "react";
import { socket } from "store/middleware/wsMiddleware";
import { getWSConnection } from "store/reducers/serverReducer";
import { useNavigate } from "react-router-dom";

const RoomsRows = ({ rooms, joinRoom }) => {
	return rooms.map(room => (
		<Table.Tr key={room.id} className={classes.partyRow}>
			<Table.Td>{room.name}</Table.Td>
			<Table.Td></Table.Td>
			<Table.Td>{room.users.length}</Table.Td>
			<Table.Td align="right">
				<Button
					variant="light"
					rightSection={<IconLogin style={{ width: rem(20), height: rem(20) }} stroke={1.5} />}
					color="blue"
					size="xs"
					radius="md"
					onClick={() => {
						joinRoom(room.roomId);
					}}
				>
					Rejoindre
				</Button>
			</Table.Td>
		</Table.Tr>
	));
};

export default function WaitingMenu() {
	const user = useSelector(selectUser);
	const dispatch = useDispatch();
	const wsConnection = useSelector(getWSConnection);
	const navigate = useNavigate();

	const [publicRooms, setPublicRooms] = useState([]);

	const createRoom = gameMode => {
		dispatch(
			wsEvent({
				event: "create_room",
				payload: {
					visibility: gameMode,
					userId: user.uuid
				}
			})
		);
	};

	const joinRoom = roomId => {
		dispatch(
			wsEvent({
				event: "join_room",
				payload: { userId: user.uuid, roomId: roomId }
			})
		);
		// Go to /play
		navigate("/play");
	};

	const updatePublicRooms = rooms => {
		setPublicRooms(rooms);
	};

	useEffect(() => {
		if (!wsConnection) return;

		socket.on("public_rooms_update", updatePublicRooms);

		socket.emit("get_public_rooms", {
			userId: user.uuid
		});

		return () => {
			socket.off("public_rooms_update", updatePublicRooms);
		};
	}, [wsConnection]);

	return (
		<Container m={0} p={0} fluid flex="1">
			<Group direction="column" align="center" justify="space-between" mb={20}>
				<Title order={1}>✏️ Petit bac</Title>

				<Button
					variant="light"
					rightSection={<IconCirclePlus style={{ width: rem(20), height: rem(20) }} stroke={1.5} />}
					radius="xl"
					size="md"
					color="orange"
					onClick={() => {
						createRoom("private");
					}}
				>
					Créer une partie
				</Button>
			</Group>

			<Table.ScrollContainer
				minWidth={800}
				style={{
					overflow: "auto",
					maxHeight: "calc(100vh - 100px)"
				}}
			>
				<Table verticalSpacing="xs">
					<Table.Thead>
						<Table.Tr>
							<Table.Th>Nom de la partie</Table.Th>
							<Table.Th>Langue</Table.Th>
							<Table.Th>Nombre de joueurs</Table.Th>
							<Table.Th></Table.Th>
						</Table.Tr>
					</Table.Thead>
					<Table.Tbody>
						{!publicRooms.length && (
							<Table.Tr>
								<Table.Td colSpan={4} align="center">
									Aucune partie publique disponible
								</Table.Td>
							</Table.Tr>
						)}
						<RoomsRows rooms={publicRooms} joinRoom={joinRoom} />
					</Table.Tbody>
				</Table>
			</Table.ScrollContainer>
		</Container>
	);
}
