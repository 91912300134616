import {
  AppShell,
  Button,
  Card,
  Container,
  Divider,
  Flex,
  NavLink,
  ScrollArea,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import lod_ from 'lodash';
// import { useDisclosure } from "@mantine/hooks";
import { IconInfoCircleFilled, IconSparkles } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../routes';
import { useSelector } from 'react-redux';
import { isUserLoggedIn } from 'store/reducers/userReducer';
import { getClientsCount } from 'store/reducers/serverReducer';
import { UserButton } from 'components/UserButton';
import logo from 'assets/images/pb3_logo4.png';
import { useEffect, useState } from 'react';
import { socket } from 'store/middleware/wsMiddleware';
import { getWSConnection } from 'store/reducers/serverReducer';
import { selectUser } from 'store/reducers/userReducer';

export function Page({ children, options }) {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const wsConnection = useSelector(getWSConnection);
  const user = useSelector(selectUser);
  const userLoggedIn = useSelector(isUserLoggedIn);
  const clientsCount = useSelector(getClientsCount);

  const [reportList, setReportList] = useState(null);
  const [themes, setThemes] = useState([]);

  let currentRoutePage = window.location.pathname;

  /**
   * User validate or unvalidate a report
   * @param {*} validated
   * @param {*} report
   * @returns
   */
  function validateReportCheck(validated, report) {
    if (!socket) {
      return;
    }
    setReportList((prev) => {
      // Update the report list for the front
      const newReportList = [...prev];
      const reportIndex = newReportList.findIndex((r) => r.word === report.word);
      newReportList[reportIndex].validated = true;

      // Update the report list for the back
      socket.emit('validate_report', {
        uuid: user.uuid,
        validated,
        report,
        newReport: newReportList,
      });

      return newReportList;
    });
  }

  /**
   * WS callback for getting the report list to validate
   * @param {*} res
   */
  function getReportToValidate(res) {
    setReportList(res?.reportList);
    setThemes(res?.themes);
  }

  useEffect(() => {
    if (!socket) {
      return;
    }

    if (!reportList && user?.uuid) {
      socket.emit(
        'get_report_list_to_validate',
        {
          uuid: user.uuid,
        },
        getReportToValidate
      );
    }

    return () => {
      socket.off('get_report_list_to_validate');
    };
  }, [wsConnection, user]);

  return (
    <AppShell
      padding={0}
      navbar={{
        width: 300,
        breakpoint: 'sm',
        // collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
      }}
      // padding="md"
    >
      <AppShell.Navbar p="md" bg="dark.8">
        {/* Login */}
        <AppShell.Section>
          <Container align="center" justify="center">
            <img src={logo} alt="logo" style={{ width: '100%' }} />
          </Container>
        </AppShell.Section>
        <Container m={20}></Container>
        {/* Nav */}
        {/* <AppShell.Section>Navigation</AppShell.Section> */}
        <AppShell.Section>
          <Divider />
        </AppShell.Section>
        <AppShell.Section my="md" component={ScrollArea}>
          {routes.map((route, key) => {
            if ((route.private && !userLoggedIn) || route.hidden) {
              return null;
            }
            return (
              <NavLink
                active={currentRoutePage === route.path}
                label={route.label}
                leftSection={route.icon}
                rightSection={route.badge}
                onClick={() => navigate(route.path)}
              />
            );
          })}
        </AppShell.Section>
        {/* Apps */}
        {/* <AppShell.Section>Applications</AppShell.Section> */}
        <AppShell.Section grow my="md" component={ScrollArea}>
          {/* <NavLink
            // active={currentRoutePage === route.path}
            label="Tap Race"
            leftSection={<IconKeyboard size="1rem" stroke={1.5} />}
            // onClick={() => navigate(route.path)}
          /> */}
        </AppShell.Section>

        {/* Report list Container */}
        <AppShell.Section>
          <ReportContainer
            user={user}
            reportList={reportList}
            themes={themes}
            validateReportCheck={validateReportCheck}
          />
        </AppShell.Section>

        {/*  <AppShell.Section>
          <NavLink label="Nouveautés" leftSection={<IconSparkles size="1rem" stroke={1.5} />} variant="subtle" active />
        </AppShell.Section> */}
        {/* Infos */}
        <AppShell.Section>
          <NavLink
            disabled
            label={<Text size="sm">Joueurs en ligne</Text>}
            leftSection={
              <Text size="sm" fw={700}>
                {clientsCount}
              </Text>
            }
          />
        </AppShell.Section>
        <AppShell.Section>
          <NavLink disabled label={<Text size="sm">{`Version ${process.env.REACT_APP_VERSION}`}</Text>} />
        </AppShell.Section>
        <Container m={10}></Container>
        {/* Social networks */}
        {/* <AppShell.Section>
          <Group>
            <ActionIcon variant="filled" aria-label="Settings">
              <IconBrandFacebook
                style={{ width: "70%", height: "70%" }}
                stroke={1.5}
              />
            </ActionIcon>

            <ActionIcon variant="filled" aria-label="Settings">
              <IconBrandDiscord
                style={{ width: "70%", height: "70%" }}
                stroke={1.5}
              />
            </ActionIcon>
          </Group>
        </AppShell.Section> */}
        <UserButton />
      </AppShell.Navbar>
      <AppShell.Main bg="dark.7" display="flex">
        <Flex direction="column" flex="1">
          {!userLoggedIn && options.displayLoginSnackbar && (
            <Flex bg={theme.colors.indigo[7]} justify="center" align="center" p={10}>
              <Text fw={600}>Connectez-vous pour accéder à toutes les fonctionnalités</Text>
              <Button variant="light" color="white" size="xs" radius="md" ml={25} onClick={() => navigate('/')}>
                Se connecter
              </Button>
            </Flex>
          )}
          <Container p="md" m={0} fluid flex="1" display="flex">
            {children}
          </Container>
        </Flex>
      </AppShell.Main>
    </AppShell>
  );
}

/**
 * Report Container component
 * Display a list of reports to validate
 * @param {*} param0
 * @returns
 */
const ReportContainer = ({ user = {}, reportList = [], themes = [], validateReportCheck = () => {} }) => {
  // Get only the unvalidate reports for today
  const unvalidateReportList = reportList?.filter((report) => report.validated !== true);

  if (lod_.isEmpty(unvalidateReportList) || lod_.isEmpty(user)) {
    return null;
  }

  // Get the first report to display
  const firstReport = unvalidateReportList[0];
  // Get the correct theme linked to the report
  const correctTheme = themes.find((theme) => theme.theme === firstReport.theme);

  if (!firstReport || !correctTheme) {
    return null;
  }

  return (
    <Card bg="var(--mantine-color-dark-5)" p="xs" radius="md" mb="xs">
      <Flex justify="space-between" align="center">
        <Flex align="center">
          <Tooltip
            label="Est-ce que le mot correspond à la catégorie ? Donnez nous votre avis pour faire progresser le jeu !"
            position="top"
          >
            <IconInfoCircleFilled
              size="1rem"
              stroke={1.5}
              style={{ marginRight: '10px' }}
              color="var(--mantine-color-indigo-6)"
            />
          </Tooltip>
          <Text fw={700}>On a besoin de toi !</Text>
        </Flex>
        <Text size="sm" fw={700} color="var(--mantine-color-dark-2)">
          {`${5 - (unvalidateReportList?.length - 1)}/5`}
        </Text>
      </Flex>
      <Divider mt={10} mb={10} />

      <Text size="lg" fw={700} color="var(--mantine-color-dark-2)">
        {`${correctTheme.label} en ${firstReport.letter}`}
      </Text>
      <Text size="md" fw={700} color="var(--mantine-color-white-1)">
        {firstReport.word}
      </Text>
      <Flex justify="space-between" mt={20}>
        <Button
          flex="1"
          mr={3}
          variant="light"
          color="var(--mantine-color-red-6)"
          size="xs"
          radius="md"
          onClick={() => {
            validateReportCheck(false, firstReport);
          }}
          disabled={user.type === 'guest'}
        >
          Refuser
        </Button>
        <Button
          flex="1"
          ml={3}
          variant="light"
          color="var(--mantine-color-green-6)"
          size="xs"
          radius="md"
          onClick={() => {
            validateReportCheck(true, firstReport);
          }}
          disabled={user.type === 'guest'}
        >
          Valider
        </Button>
      </Flex>
      {user.type === 'guest' && (
        <Tooltip label="Connectez vous avec Discord pour utiliser cette fonctionnalité">
          <Card mt={20} p={5} bg="#fc0e0e3d" radius="md" align="center">
            <Text fw={600} size="sm">
              Votre compte n'est pas vérifié
            </Text>
          </Card>
        </Tooltip>
      )}
    </Card>
  );
};
